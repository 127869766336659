<template>
  <div class="px-5 sm:px-52 mx-auto my-20">
    <div style="font-size: 20px">
      <div>
        <h2 class="font-extrabold color-purple text-3xl RobotoMedium" >Préstamo Simple</h2>
        <div class="liner-yellow"></div>
        <p class="color-grey mt-20 font-semibold	">
          FONCABSA sociedad cooperativa, ofrece préstamos de tipo simple con
          destino libre, para financiar cualquier oportunidad, emergencia o
          imprevisto, con cuotas fijas y tasa de interés fija, que representa un
          apoyo importante para el bienestar personal y familiar.
        </p>
      </div>
    </div>
    <div class="mt-20">
      <div>
        <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Características</h3>

        <div style="display: flex; font-size: 18px" class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Préstamo otorgado en Pesos Mexicanos.</p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Plazo de 1 a 18 meses.</p>
        </div>
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Periodicidad de pago: Quincenal.</p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Libre destino del crédito.</p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Pagos Fijos vía nómina si existe convenio o con cargo a tarjeta.
          </p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Tasa de interés Fija.</p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Pagos a Capital SIN PENALIZACIÓN.</p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Disposición del Crédito por Cheque o Transferencia.
          </p>
        </div>
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Protección del Saldo deudor, sin costo.</p>
        </div>
      </div>
    </div>
    

    <div class="mt-20">
      <div>
        <h3 class="font-extrabold color-purple  text-2xl RobotoMedium">Requisitos De Contratación</h3>

        <div style="display: flex; font-size: 18px" class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Tener entre 18 y 65 años de edad.</p>
        </div>
        
        <div style="display: flex; font-size: 18px; margin-top: -2px; " class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Estar inscrito como socio.</p>
        </div>
        
         <div style="display: flex; font-size: 18px; margin-top: -2px; " class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Buen historial crediticio.</p>
        </div>
        
         <div style="display: flex; font-size: 18px; margin-top: -2px; " class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Llenar y firmar la solicitud.</p>
        </div>
        
         <div style="display: flex; font-size: 18px; margin-top: -2px; " class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Llenar contrato de préstamo.</p>
        </div>
        
        <div style="display: flex; font-size: 18px; margin-top: -2px; " class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Llenar autorizo de descuento.</p>
        </div>
        
        <div style="display: flex; font-size: 18px; margin-top: -2px; " class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Buen historial en tus aportaciones de ahorro.</p>
        </div>
      </div>
    </div>

    

    <div class="mt-20">
      <div>
        <h3 class="font-extrabold color-purple  text-2xl RobotoMedium">Documentación Requerida</h3>

        <div style="display: flex; font-size: 18px" class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Identificación Oficial Vigente: Credencial para votar y/o Pasaporte.
          </p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Comprobante de ingresos de los últimos 3 meses.
          </p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Comprobante de domicilio: Recibo de luz, agua o teléfono.
          </p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Documentación complementaria: CURP, RFC.</p>
        </div>
        
        <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Solicitud debidamente llenada y firmada.</p>
        </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Préstamo sujeto a autorización.
          </p>
        </div>
      </div>
    </div>

    

    <div class="mt-20">
      <div>
        <h3 class="font-extrabold color-purple  text-2xl RobotoMedium">Costos y Comisiones</h3>

        <div style="display: flex; font-size: 18px" class="mt-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">Costo Anual Total (CAT)</p>
        </div>
        <div style="display: flex; font-size: 18px" class="ml-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            CAT PROMEDIO 23.9% Sin IVA, para fines informativos y de
            comparación.
          </p>
        </div>
        <div style="display: flex; font-size: 18px; margin-top: -2px" class="ml-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            Calculado al 01 de julio  de 2022 para un crédito de 50,000 pesos con 18 pagos fijos y una tasa de interés fija anual del 18% (sin IVA) y comisión del 3% sobre el monto de crédito.
          </p>
        </div>
        <div style="display: flex; font-size: 18px; margin-top: -2px" class="ml-10">
          <p class="color-grey text-info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </p>
          <p class="color-grey text-info">
            CAT vigente al 31 de diciembre de 2022.
          </p>
        </div>
        
      </div>
    </div>

    <div style="display: flex; font-size: 18px" class="mt-10">
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">Tasas de interés</p>
    </div>
    
    <div style="display: flex; font-size: 18px" class="ml-10">
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">Tipo de tasa de interés: Fija.</p>
    </div>
    <div
      style="display: flex; font-size: 18px; margin-top: -2px"
      class="ml-10"
    >
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">
        Tasa de interés ordinaria: Tasa de interés anual del 18% más 3% de costo
        por administración más IVA.
      </p>
    </div>
    <div
      style="display: flex; font-size: 18px; margin-top: -2px"
      class="ml-10"
    >
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">
        La tasa se mantiene fija durante toda la vigencia del crédito.
      </p>
    </div>
    

    <div style="display: flex; font-size: 18px" class="mt-10">
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">Comisiones</p>
    </div>
    <div style="display: flex; font-size: 18px" class="ml-10">
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">
        Contratación o Apertura: Factor máximo 3%, sobre monto del Préstamo.
      </p>
    </div>
    <!-- <div
      style="display: flex; font-size: 18px; margin-top: -2px"
      class="ml-10"
    >
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">Periodicidad de cobro: Única vez.</p>
    </div>
    <div
      style="display: flex; font-size: 18px; margin-top: -2px"
      class="ml-10"
    >
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">
        Forma de pago: financiado sin intereses dentro del plazo.
      </p>
    </div> -->
    <div
      style="display: flex; font-size: 18px; margin-top: -2px"
      class="ml-10"
    >
      <p class="color-grey text-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </p>
      <p class="color-grey text-info">
        A esta comisión deberá sumarse el correspondiente Impuesto al Valor
        Agregado (IVA).
      </p>
    </div>
    
    <!-- 
    <p class="color-grey mt-10"  style=" font-size: 18px">
        Consulta términos, condiciones, requisitos de contratación, restricciones, costos y comisiones en:
    </p> -->
  </div>
</template>

<script>
export default {
  name: "PrestamoSimple",
};
</script>
<style scoped>
.liner-yellow {
  width: 100px;
  background: #f8c603;
  height: 5px;
}
.text-info{
  font-family: "RobotoRegular";
}
.color-purple {
  color: #810042;
}
.color-grey {
  color: #726659;
}
</style>
