<template>
    <div>
        <div>
            <Menu :list="menuList"/>
            <PrestamoSimple/>
            <Footer/>
        </div>
        
    </div>
    
</template>

<script>
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer3.vue";
import PrestamoSimple from "../components/PrestamoSimple.vue";

import menuOptions  from "@/helpers/menu.js";
import { ref, onBeforeMount } from 'vue';

export default {
    setup() {
        let menuList = ref([]);

        onBeforeMount(async () => {
            await getMenuOptionsList();
        });

        const getMenuOptionsList = async () => {
            await menuOptions.getMenuOptions().then(resp => {
                menuList.value = resp.data.FONCABSA;
            }).catch(err => {
                console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
            });
        };

        return {
            menuList
        }
    },
    components: {
        Menu,
        Footer,
        PrestamoSimple
    }
}
</script>